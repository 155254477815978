import Box from 'UIKit/Box/Box'
import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Autoplay, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'

import { BoxProps } from 'UIKit/Box/types'
import { CarouselProps } from './types'

const Carousel: React.FC<CarouselProps & BoxProps> = ({
  slides,
  onSwiper = () => {},
  modules = [],
  thumbs,
  slidesPerView,
  autoplay = false,
  pagination = false,
  loop = false,
  effect,
  onActiveSlideChange,
  spaceBetween = 0,
  initialSlide,
  ...props
}) => {
  const controlledRef = useRef<typeof thumbs.swiper>(thumbs?.swiper || null)

  const [activeSlide, setActiveSlide] = useState(0)

  const handleStopAutoPlay = () => {
    if (typeof controlledRef.current?.autoplay?.stop === 'function' && autoplay) {
      controlledRef.current.autoplay.stop()
    }
  }

  const handleStartAutoPlay = () => {
    if (typeof controlledRef.current?.autoplay?.start === 'function' && autoplay) {
      controlledRef.current.autoplay.start()
    }
  }

  return (
    slides && (
      <Wrapper {...props} onMouseOver={handleStopAutoPlay} onMouseOut={handleStartAutoPlay}>
        <Container>
          <StypedSwiper
            spaceBetween={spaceBetween}
            initialSlide={initialSlide}
            onSwiper={(swiper) => {
              controlledRef.current = swiper
              if (onSwiper) {
                onSwiper(swiper)
              }
            }}
            effect={effect}
            autoplay={autoplay}
            thumbs={thumbs}
            pagination={pagination}
            modules={[Controller, Autoplay, ...modules]}
            slidesPerView={slidesPerView}
            loop={loop}
            onSlideChange={(swiper) => {
              const index = swiper.realIndex
              setActiveSlide(index)

              if (onActiveSlideChange) {
                onActiveSlideChange(index)
              }
            }}
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.key}>{slide.content}</SwiperSlide>
            ))}
          </StypedSwiper>
        </Container>
        {pagination && (
          <StyledPaginationContainer>
            {slides.map((slide, index) => (
              <StyledPaginationButton
                key={slide.key}
                className={`item-pagination-${index === activeSlide ? 'active' : 'inactive'}`}
                $Active={index === activeSlide}
                onClick={() => (loop ? controlledRef.current.slideToLoop(index) : controlledRef.current.slideTo(index))}
              />
            ))}
          </StyledPaginationContainer>
        )}
      </Wrapper>
    )
  )
}
export const Wrapper = styled(Box)`
  position: relative;
  width: inherit;
`

const Container = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const StypedSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`

export const StyledPaginationContainer = styled(Box)`
  position: absolute;
  bottom: 24px;
  right: 12px;
  z-index: 2;

  display: flex;

  ${({ theme }) => theme.mediaQueries.sm} {
    bottom: 12px;
  }
`

const StyledPaginationButton = styled(Box)<{ $Active: boolean }>`
  width: 6px;
  height: 6px;
  margin-left: 2px;
  border-radius: 6px;
  background: ${({ theme, $Active }) => ($Active ? theme.colors.text : theme.colors.textDisable)};

  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.text};
  }

  ${({ $Active }) =>
    $Active
      ? css`
          width: 20px;
        `
      : ''};

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 8px;
    height: 8px;
    margin-left: 4px;
    border-radius: 8px;

    ${({ $Active }) =>
      $Active
        ? css`
            width: 22px;
          `
        : ''};
  }
`

export default Carousel
