import { AuthMethod } from 'config/constants/auth'
import { CountryEnum } from 'config/constants/country'
import { ChainIdEnum } from 'config/constants/network'
import { HusdUnlockTxnStatusEnums } from 'config/constants/transactions'
import { DepositedTokenInfo, GameTag, ProviderInfo, ScreenTag, Token } from 'config/types'
import { Verify2FAResponse } from 'config/types/auth'
import { UserBonus } from 'config/types/bonus/userBonus'
import { WelcomePackage } from 'config/types/bonus/welcomePackage'
import { FiatCurrency } from 'config/types/fiat'
import { MinWithdrawTokenAmounts } from 'config/types/payment'
import { ProfileTierInfo, WagerInfo } from 'config/types/profile'
import { PinnedPromotion } from 'config/types/promotion'
import { ReferralBalanceTokenAmount } from 'config/types/referral'
import { WalletType } from 'config/types/wallet'
import { TraditionalWithdrawFiatAction } from 'views/Payment/components/Withdraw/WithdrawByFiat/TraditionalWithdrawButton'

export class BaseResponse<T> {
  code: 'success' | string
  message?: string
  errors?: any[]
  data?: T
}

export class Paging<T> {
  paging?: PagingInfo
  items: T[]
}

export class PagingInfo {
  limit?: number
  offset?: number
  total?: number
}

export type MetaDataResponse = {
  clientCountry?: CountryEnum
  providers: ProviderInfo[]
  defaultScreenTags: ScreenTag[]
  tags: GameTag[]
  serverTime: number
  tokenInfoes: DepositedTokenInfo[]
  networkIds: string[]
  tiers: ProfileTierInfo[]
  pinPromotions: PinnedPromotion[]
  welcomePackage: WelcomePackage
  timeChangeDisplayNameInDays: number
  minWithdrawTokenAmounts: MinWithdrawTokenAmounts
  isDisplayCommitment: boolean
}

export type UserDataResponse = {
  balances: BalanceResponse[]
  profile: {
    tier: number
    totalWager: string
    totalBet: number
    displayName: string
    avatar: string
    canUpdateDisplayNameAt: number
    userCode: string
    registeredDate: Date
    email?: string
    telegram: {
      connected: boolean
      username: string
      id: number
    }
  }
  welcomePackage: {
    isClaimed: boolean
    expiredAt: number
  }
  userBonuses: UserBonus[]
  notificationUnreadCount: {
    system: number
    personal: number
  }
  bonusBalances: {
    HUSD: string
  }
  settings: {
    disableAirdropAnimation: boolean
    disableZeroBalance: boolean
    disablePublicDisplayName: boolean
    disableStatistic: boolean
    disablePlayedGameSection: boolean
    disableDisplayInFiat: boolean
    selectedFiatCurrency: FiatCurrency
    /// TODO: 2FA: Remove optional attributes: after BE remove API
    enable2FA?: boolean
    isEnable2FAForLogin: boolean
    isEnable2FAForWithdraw: boolean
    isEnable2FAForTip: boolean
  }
  authBy: AuthMethod
}

export type UserAvatar = {
  id: string
  name: string
  url: string
}
export type UserData = {
  balances: BalanceResponse[]
  profile: {
    tier: ProfileTierInfo
    wager: WagerInfo
    displayName: string
    avatar: string
    totalBet?: number
    canUpdateDisplayNameAt: number
    userCode: string
    registeredDate: Date
    telegram: {
      connected: boolean
      username: string
      id: number
    }
  }
  welcomePackage: {
    isClaimed: boolean
    expiredAt: number
  }
}

export class HunnyRequest<T = any> {
  call: () => Promise<T>
  cancel: () => void
}

export type QueryGhostParams<T = any> = {
  fields?: (keyof T)[]
  filter?: string
  order?: string
  include?: string[]
  limit?: number | 'all'
  page?: number
}
export class PrepareSignMessagePayload {
  walletType: WalletType
  address?: string
  currency: Token
}
export class PrepareSignMessageResponse {
  address?: string
  acceptMessageText: string
  acceptMessage: any
  isRequire2FA?: boolean
  withdrawLockTime?: number
}

export class SignInSettings {
  isRequire2FA: boolean
}

export type TonProof = {
  tonNetwork: number
  stateInit: string
  proof: {
    domain: {
      lengthBytes: number
      value: string
    }
    payload: string
    signature: string
    timestamp: number
  }
}

export class SignInByWalletPayload {
  acceptMessage: string
  address: string
  deviceUid: string
  messageSignature: string
  referralCode?: string
  affilkaCode?: string
  husdBonusAmount?: number
  totpAuthCode?: string
  webAppData?: string

  tonProof?: TonProof
}

export class SignInByEmailPayload {
  password: string
  username: string
  deviceUid: string
  nonceId: string
  webAppData?: string
}

export class SignInByTelegramPayload {
  deviceUid: string
  webAppData: string
  isLoginWidget?: boolean
  referralCode?: string
  affilkaCode?: string
}

export class LoginResponse {
  accessToken?: string
  refreshToken?: string
  uid?: number
  isSignUp?: boolean
  husdBonusAmount?: number
  prepare2FAData?: Partial<VerificationResponse>
  username: string
  authBy: AuthMethod
}

export class BalanceResponse {
  currency: string
  amount: string
  network: ChainIdEnum
}

export class TokenUsdPriceResponse {
  [tokenName: string]: string
}

export class TraditionalSignUpPreparePayload {
  username: string
  referralCode: string
}

export class PasswordNonceResponse {
  id: string
  value: string
  key: string
  token?: string
}

export class VerificationResponse {
  expireTime: number
  nextResentTime: number
  token: string
  withdrawLockTime?: number
}

export class RegisterResponse {
  uid: number
  husdBonusAmount?: number
  username: string
  authBy: AuthMethod
}

export class Verification {
  type: string
  data: {
    token: string
    otp: string
    expireTime?: number
    expiredTime?: number
    nextResentTime?: number
  }
}
export interface RegisteredEmailPayload {
  username: string
  password: string
  nonceId: string
  verifications: Verification[]
  referralCode?: string
  affilkaCode?: string
  husdBonusAmount?: number
  deviceUid: string
  passwordNonce?: {
    key: string
    value: string
    id: string
  }
  webAppData?: string
}

export interface PrepareTokenNetworkPayload {
  chainCode: string
  chainType: string
  currency?: string
}

export interface TraditionalPaymentPayload {
  otp?: string
  verificationCode?: string
}

export interface PrepareWithdrawPayload
  extends PrepareTokenNetworkPayload,
    TraditionalPaymentPayload,
    Verify2FAResponse {
  fee: string
  toAddress: string
  value?: string
  memo?: string
}

export interface PrepareTraditionalWithdrawFiatPayload extends Pick<TraditionalWithdrawFiatAction, 'payload'> {}
export interface TraditionalWithdrawFiatPayload
  extends TraditionalPaymentPayload,
    Verify2FAResponse,
    PrepareTraditionalWithdrawFiatPayload {}

export interface PrepareTraditionalTipPayload {
  amount: string
  currency: Token
  uid: number
  message: string
}

export interface TraditionalSendTipPayload
  extends PrepareTraditionalTipPayload,
    TraditionalPaymentPayload,
    Verify2FAResponse {}

export interface PrepareReferralClaim {
  selectedToken: Token
  address: string
  items: ReferralBalanceTokenAmount[]
}

export interface PrepareReferralClaimRes {
  items: ReferralBalanceTokenAmount[]
  sum: string
}

export interface TopWin {
  gameName: string
  providerName: string
  amount: string
  thumbnail: string
  gameSlug: string
}

export enum GameWinTypeEnum {
  BigWin = 1,
  LuckyWin = 2,
}

export enum LiveBetTypeEnum {
  LatestBet = 1,
  HighRollers = 2,
}

export enum SurveyTypeEnum {
  CancelBonus = 'cancel_bonus',
  SignUp = 'signup',
}

export interface HUSDClaimedTransaction {
  createdAt: number
  amount: string
  token: Token
  status: HusdUnlockTxnStatusEnums
  note?: string
}

export interface CheckSurveyResponse {
  isAnswered: string
  surveyCode: SurveyTypeEnum
}

